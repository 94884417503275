import React from 'react'
import iconsquare from '../../../assets/images/icon-square.png'
import Header from '../../../header'
import axios from 'axios';
import config from '../../../config'
class Irononpatches extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={
                data:[],
                count : 1,
                ironloader : false
            }
        }
        this.Irononpatchesback = this.Irononpatchesback.bind(this)
        this.Irononpatchesnext = this.Irononpatchesnext.bind(this)
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e)
    {
        
        let name=e.target.name
        let value=e.target.value
        this.setState({[name]:value})
    }
    Irononpatchesback()
    {
        let store_name = sessionStorage.getItem('store');
        this.props.history.push('/'+ store_name);
    }
    Irononpatchesnext()
    {
        this.props.history.push('/hand-held-gun');
    }
    componentDidMount()
    {
        this.setState({
            ironloader : true
        })
        let store_id = sessionStorage.getItem('store_id');
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}getAssests?type=iron-on-patches&store_id=${store_id}`
        axios.get(apiUrl , {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            if(response.data.success == 1)
            {
                this.setState({
                    ironloader : false
                })
                this.setState({data:response.data.data})
            }
        })
        .catch((err) => {

        });
    }
    render()
    {
        const {ironloader,count} = this.state;
        let store_name = sessionStorage.getItem('store');
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div design-menu-sec design-logo-sec">
                                    <div className="form-logo-section sec-center">
                                        <a href={'#/' + store_name}><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>LOOKBOOK</h3>
                                    <h4>Iron on patches</h4>
                                    {ironloader ? 
                                    <div className="spinner-outer">
                                    <div class="spinner-border logo" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    </div>
                                    :
                                    <div className="col-md-12 sec-center left">
	                                    <div className="design-logo-grid-outer design-graphics-grid-outer">
                                            <div className="design-logo-grid-inner">
                                            {this.state.data && this.state.data.map((item, index)=>{
                                                return(
	                                            <div key={index} className="logo-grid-box">
	                                                <div className="logo-grid-box-img">
	                                                    <img id={item.id} src={item.image_path} alt="logo1" className="img-fluid"/>
	                                                </div>
	                                                <div className="logo-grid-box-content">
                                                        <h5>Iron on patches {count + index}</h5>
	                                                </div>
	                                            </div>
                                                )
                                            })}
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className="col-md-12 sec-center left">
                                        <div className="footer-btn"> 
                                            <div className="footer-left-btn"> 
                                                <button onClick={this.Irononpatchesback} className="black_btn">Back</button>
                                            </div>
                                            <div className="footer-right-btn"> 
                                                <button onClick={this.Irononpatchesnext} className="black_btn">Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Irononpatches;