import React from 'react'
import iconsquare from '../../../assets/images/icon-square.png'
import Header from '../../../header'
import axios from 'axios';
import config from '../../../config'
class AlphabetsBlack extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={
                alphabetblackloader : false
            }
        }
        this.alphabetsback = this.alphabetsback.bind(this)
        this.alphabetsnext = this.alphabetsnext.bind(this)
    }
    alphabetsback()
    {
        this.props.history.push('/alphabets');
    }
    alphabetsnext()
    {
        this.props.history.push('/alphabets-blue');
    }
    componentDidMount()
    {
        this.setState({
            alphabetblackloader : true
        })
        let apiDynamicUrlAddress = config.default.api1.host;
        let store_id = sessionStorage.getItem('store_id');
        let apiUrl = `${apiDynamicUrlAddress}getAssests?type=alphabets-black&store_id=${store_id}`
        axios.get(apiUrl , {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            if(response.data.success == 1)
            {
                this.setState({
                    alphabetblackloader : false
                })
                this.setState({alphabetsblack:response.data.data})
            }
        })
        .catch((err) => {

        });
    }
    render()
    {
        const  {alphabetblackloader} = this.state;
        let store_name = sessionStorage.getItem('store');
        let button;
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div design-menu-sec design-logo-sec design-icon-sec">
                                    <div className="form-logo-section sec-center">
                                        <a href={'#/' + store_name}><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>LOOKBOOK</h3>
                                    <h3 class="subheading_alphabets">Black Font</h3>
                                    <h4 class="m30">Alphabets | Symbols | Numbers</h4>
                                    {alphabetblackloader ?
                                    <div className="spinner-outer">
                                    <div class="spinner-border logo" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    </div>
                                    :
                                    <div>
                                        <div className="col-md-12 sec-center left">
                                            <div className="design-logo-grid-outer design-graphics-grid-outer">
                                                <div className="design-logo-grid-inner">
                                                    {this.state.alphabetsblack && this.state.alphabetsblack.map((item,index)=>{
                                                        let imgpath = item.image_path
                                                        var imgname = imgpath.substring(imgpath.lastIndexOf('/') + 1);
                                                        let imagename = imgname.split('.png')
                                                        return(
                                                            <div key={index} className="logo-grid-box">
                                                                <div className="logo-grid-box-img">
                                                                    <img id={item.id} src={item.image_path} alt="logo1" className="img-fluid"/>
                                                                </div>
                                                                <div className="logo-grid-box-content">
                                                                    <h5>{imagename}</h5>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className="col-md-12 sec-center left">
                                        <div className="footer-btn"> 
                                            <div className="footer-left-btn"> 
                                                <button onClick={this.alphabetsback} className="black_btn">Back</button>
                                            </div>
                                            <div className="footer-right-btn"> 
                                                <button onClick={this.alphabetsnext} className="black_btn">Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default AlphabetsBlack;