import React, { Suspense } from "react";
import {Switch, HashRouter} from "react-router-dom";
import Routes from "./shared/Routes";
import BrowserRoutes from './shared/BrowserRoutes'
import History from "./shared/history";
import {isIPad13, isMobile, isDesktop} from "react-device-detect";
function App() {
  return (
    <div>
      <HashRouter history={History}>
        <Suspense fallback={<div></div>}>
           {/* {isIPad13 ? <Switch>{Routes()}</Switch> : isMobile ? <Switch>{Routes()}</Switch> : <Switch>{BrowserRoutes()}</Switch>} */}
           <Switch>{Routes()}</Switch>
        </Suspense>
      </HashRouter>
    </div>
  );
}
export default App;