import React from 'react'
import Header from '../../../header'
import iconsquare from '../../../assets/images/icon-square.png'
import axios from 'axios';
import config from '../../../config'
class Designs extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={

            }
        }
        this.designback=this.designback.bind(this);
        this.Vinylheattransfer = this.Vinylheattransfer.bind(this);
        this.Irononpatches = this.Irononpatches.bind(this);
        this.Handheldgun  =this.Handheldgun.bind(this);
        this.designext = this.designext.bind(this);
        this.dtgPrint = this.dtgPrint.bind(this);
    }
    designback()
    {
        this.props.history.push('/tshirt-selection')
    }
    designext()
    {
        this.props.history.push('/vinyl-heat-transfer');
    }
    Vinylheattransfer()
    {
        this.props.history.push('/vinyl-heat-transfer')
    }
    Irononpatches()
    {
        this.props.history.push('/iron-on-patches')
    }
    Handheldgun()
    {
        this.props.history.push('/hand-held-gun')
    }
    dtgPrint()
    {
        this.props.history.push('/dtg-print')
    }
    componentDidMount()
    {
        sessionStorage.clear();
        var url = this.props.location.pathname;
        var store = url.substring(url.lastIndexOf('/') + 1);
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}storeSlug`
        var formData = new FormData();
        formData.append("slug", '1');
        formData.append("slug_name", store);
        axios.post(apiUrl , formData, {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
        if(response.data.success == 1)
        {
            sessionStorage.setItem('store_id',response.data.data_id)
            sessionStorage.setItem('store',response.data.inserted_records.slug)
        }
        else{
            
        }
        })
        .catch((err) => {  
        });
    }
    render()
    {
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div design-menu-sec">
                                    <form>
                                    <div className="form-logo-section sec-center">
                                        <a><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>LOOKBOOK</h3>
                                    <div className="col-md-12 sec-center left">
                                        <div className="design-menu-inner">
                                            <ul>
                                                <li><button onClick={this.Vinylheattransfer}>VINYL HEAT TRANSFER</button></li>
                                                <li><button onClick={this.Irononpatches}>IRON ON PATCHES</button></li>
                                                <li><button onClick={this.Handheldgun}>HAND HELD INKJET GUN</button></li>
                                                <li><button onClick={this.dtgPrint}>DTG PRINT</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Designs;