import React from 'react'
import logo from './assets/images/logonew.png'
class Header extends React.Component{
    render()
    {
        return(
            <div className="header-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="logo-section">
                                <a><img src={logo} alt="logo" className="img-fluid brand-logo"/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Header;