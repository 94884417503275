import { Route , useParams, Redirect} from 'react-router-dom';
import React from 'react';
import Designs from '../Tshirt-selection/designs/mini/designs';
import Vinylheattransfer from '../Tshirt-selection/designs/mini/vinylheattransfer'
import Irononpatches from '../Tshirt-selection/designs/mini/irononpatches'
import Handheldgun from '../Tshirt-selection/designs/mini/handheldgun'
import DtgPrint from '../Tshirt-selection/designs/ipad/dtgprint'
import Logos from '../Tshirt-selection/designs/ipad/logos'
import Graphics from '../Tshirt-selection/designs/ipad/graphics'
import Alphabets from '../Tshirt-selection/designs/ipad/alphabets'
import AlphabetsBlack from '../Tshirt-selection/designs/ipad/alphabets-black'
import AlphabetsBlue from '../Tshirt-selection/designs/ipad/alphabets-blue'
import AlphabetsLondon from '../Tshirt-selection/designs/ipad/alphabets-london'
import Exclusiveicons from '../Tshirt-selection/designs/ipad/exclusive-icon'
export default () => [
    <Route key="design" exact path="/retail01" component={Designs}/>,
    <Route key="design" exact path="/retail02" component={Designs}/>,
    <Route key="vinyl-heat-transfer" path="/vinyl-heat-transfer" component={Vinylheattransfer}/>,
    <Route key="iron-on-patches" path="/iron-on-patches" component={Irononpatches}/>,
    <Route key="hand-held-gun" path="/hand-held-gun" component={Handheldgun}/>,
    <Route key="dtg-print" path="/dtg-print" component={DtgPrint}/>,
    <Route key="logos" path="/logos" component={Logos}/>,
    <Route key="graphics" path="/graphics" component={Graphics}/>,
    <Route key="alphabets" path="/alphabets" component={Alphabets}/>,
    <Route key="alphabets-black" path="/alphabets-black"  component={AlphabetsBlack}/>,
    <Route key="alphabets-blue" path="/alphabets-blue"  component={AlphabetsBlue}/>,
    <Route key="alphabets-london" path="/alphabets-london"  component={AlphabetsLondon}/>,
    <Route key="exclusiveicons" path="/exclusiveicons"  component={Exclusiveicons}/>,
];
