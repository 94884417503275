import React from 'react'
import iconsquare from '../../../assets/images/icon-square.png'
import Header from '../../../header'
import axios from 'axios';
import config from '../../../config'
class Logos extends React.Component{
    constructor(props)
    {
        super(props)
        {
            this.state={
                data:[],
                logoloader : false,
                count : 1
            }
        }
        this.logosback = this.logosback.bind(this)
        this.logosnext = this.logosnext.bind(this)
    }
    logosback()
    {
        this.props.history.push('/dtg-print');
    }
    logosnext()
    {
        this.props.history.push('/graphics');
    }
    componentDidMount()
    {
        this.setState({
            logoloader : true
        })
        let apiDynamicUrlAddress = config.default.api1.host;
        let store_id = sessionStorage.getItem('store_id');
        let apiUrl = `${apiDynamicUrlAddress}getAssests?type=logos&store_id=${store_id}`
        axios.get(apiUrl , {
            crossDomain : true,
            enablePreflight : false
        }).then((response) => {
            if(response.data.success == 1)
            {
                this.setState({
                    logoloader : false
                })
                this.setState({data:response.data.data})
            }
        })
        .catch((err) => {

        });
    }
    render()
    {
       const {logoloader, count}  = this.state;
       let store_name = sessionStorage.getItem('store');
        return(
            <div>
                <Header/>
                <section className="home-register-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <div className="home-register-form-div design-menu-sec design-logo-sec">
                                    <div className="form-logo-section sec-center">
                                        <a href={'#/' + store_name}><img src={iconsquare} alt="logo" className="img-fluid brand-logo"/></a>
                                    </div>
                                    <h3>LOOKBOOK</h3>
                                    <h4>Logos</h4>
                                    {logoloader ? 
                                    <div className="spinner-outer">
                                    <div class="spinner-border logo" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    </div>
                                    :
                                    <div>
                                        <div className="col-md-12 sec-center left">
                                            <div className="design-logo-grid-outer">
                                                <div className="design-logo-grid-inner">
                                                    {this.state.data && this.state.data.map((item, index)=>{
                                                        return(
                                                        <div key={index} className="logo-grid-box">
                                                            <div className="logo-grid-box-img">
                                                                <img id={item.id} src={item.image_path} className="img-fluid"/>
                                                            </div>
                                                            <div className="logo-grid-box-content">
                                                                <h5>Logo {count + index}</h5>
                                                            </div>
                                                        </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
	                                <div className="col-md-12 sec-center left">
	                                    <div className="footer-btn"> 
	                                        <div className="footer-left-btn"> 
	                                            <button onClick={this.logosback} className="black_btn">Back</button>
	                                        </div>
	                                        <div className="footer-right-btn"> 
	                                            <button onClick={this.logosnext} className="black_btn">Next</button>
	                                        </div>
	                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
        )
    }
}
export default Logos;